import React from 'react';
import Sudoku from '../../components/Sudoku/Sudoku';
import { shallowEqual, useSelector } from 'react-redux'
import './Sudoku.scss';

const SudokuPage = () => {
    const settings = useSelector((state) => state.settings, shallowEqual) || null;
    return (
        <div className={`page-container size-${settings.size} hide-${settings.hide_timer ? 'timer' : ''}`} style={{backgroundColor: settings.background ?  settings.background : '#ffffff'}}>
            <Sudoku />
        </div>
    )
}

export default SudokuPage
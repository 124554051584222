import store from "../store";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

function setSettings(identity) {
  return {
    type: 'SET_SETTINGS',
    payload: identity,
  };
}

const default_ojiram_settings = {
  background: '#ffffff',
  size: 'medium',
  hide_timer: false
}

const getSettings = async () => {
  try {
    store.dispatch(setSettings(cookies.get('ojiram_settings') || default_ojiram_settings));
  } catch (err) {
    console.log("error grabIdentity");
  }
};

const updateSettings = async (settings) => {
  try {
    let ojiram_settings = cookies.get('ojiram_settings') || default_ojiram_settings;
    cookies.set('ojiram_settings', { ...ojiram_settings, ...settings }, { path: '/' });
    store.dispatch(setSettings({ ...ojiram_settings, ...settings }))
  } catch (err) {
    console.log("error removeIdentity");
  }
};
const removeSettings = async () => {
  try {
    let ojiram_settings = cookies.get('ojiram_settings') || default_ojiram_settings;
    cookies.set('ojiram_settings', { ...ojiram_settings, ...default_ojiram_settings }, { path: '/' });
    store.dispatch(setSettings({ ...ojiram_settings, ...default_ojiram_settings }))
  } catch (err) {
    console.log("error removeIdentity");
  }
};

export { getSettings, updateSettings, removeSettings, default_ojiram_settings, setSettings };

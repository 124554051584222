export const pears = [ // ['row, column']
    ['00', '01', '02', '10', '11', '12', '20', '21', '22'],
    ['03', '04', '05', '13', '14', '15', '23', '24', '25'],
    ['06', '07', '08', '16', '17', '18', '26', '27', '28'],
    ['30', '31', '32', '40', '41', '42', '50', '51', '52'],
    ['33', '34', '35', '43', '44', '45', '53', '54', '55'],
    ['36', '37', '38', '46', '47', '48', '56', '57', '58'],
    ['60', '61', '62', '70', '71', '72', '80', '81', '82'],
    ['63', '64', '65', '73', '74', '75', '83', '84', '85'],
    ['66', '67', '68', '76', '77', '78', '86', '87', '88'],
];

const shuffled = (str) => str.split('').sort(function () { return 0.5 - Math.random() }).join('');

export function generateSudoku() {
    let basic_matrix = new Array(9).fill(null).map(() => new Array(9).fill(null).map(() => shuffled('123456789')));
    for (let i = 0; i < 9; i++) {
        for (let ii = 0; ii < 9; ii++) {
            let random_number = sortByFrequency(basic_matrix[i].filter((a, b) => typeof a !== 'number').join('').split(''));
            let index = findShortestString(basic_matrix[i], random_number);
            if (isNaN(parseInt(random_number))) console.log('bad nuber');
            basic_matrix[i][index] = parseInt(random_number);

            //row clean random number
            for (let x = 0; x < 9; x++) {
                if (typeof basic_matrix[i][x] !== 'number') basic_matrix[i][x] = basic_matrix[i][x].replace(random_number, '');
            }
            //column clean random number
            for (let y = i + 1; y < 9; y++) {
                if (typeof basic_matrix[y][index] !== 'number') basic_matrix[y][index] = basic_matrix[y][index].replace(random_number, '');
            }
            //pears clean random number
            let pears_index = `${i}${index}`;
            let pears_by_index = pears.filter((a, b) => a.includes(pears_index))[0]
            for (let z = 0; z < 9; z++) {
                let pear = pears_by_index[z];
                if (pear !== pears_index && typeof basic_matrix[pear[0]][pear[1]] !== 'number') {
                    basic_matrix[pear[0]][pear[1]] = basic_matrix[pear[0]][pear[1]].replace(random_number, '');
                }
            }
        }
    }
    return basic_matrix
}

function findShortestString(array, random_number) {
    let index = 0;
    let elementLength = 9;
    for (let i = 0; i < 9; i++) {
        if (typeof array[i] !== 'number' && array[i].includes(random_number) && array[i].length < elementLength) {
            index = i; elementLength = array[i].length;
        }
    }
    // console.log('array , num = ', array, index)
    return index;
}

function sortByFrequency(array) {
    let frequency = {};

    array.forEach(function (value) { frequency[value] = 0; });

    let uniques = array.filter(function (value) {
        return ++frequency[value] === 1;
    });

    return uniques.sort(function (a, b) {
        return frequency[b] - frequency[a];
    })[uniques.length - 1]
}

export function checkIfSudokuIsValid(array) {
    let merged = array.flat(1);
    return merged.includes(NaN) || merged.includes('')
}




export function getSudoku(difficulty) {
    let sudoku = generateSudoku();
    while (checkIfSudokuIsValid(sudoku)) {
        sudoku = generateSudoku();
    }
    return applyDifficulty(sudoku, difficulty)
}


export function applyDifficulty(sudoku, difficulty) {
    let to_delete = pears.flat(1).sort(() => .5 - Math.random()).slice(0, difficulty);
    for (let i = 0; i < to_delete.length; i++) {
        sudoku[to_delete[i][0]][to_delete[i][1]] = '';
    }
    return sudoku
}


export function checkIfGameIsGood(sudoku) {
    let errors = [];
    sudoku.map((row, i) => {
        if ([...row].sort().join('') !== '123456789') {
            errors = [...errors, ...[`${i}0`, `${i}1`, `${i}2`, `${i}3`, `${i}4`, `${i}5`, `${i}6`, `${i}7`, `${i}8`]]
        }
        let column = [sudoku[0][i], sudoku[1][i], sudoku[2][i], sudoku[3][i], sudoku[4][i], sudoku[5][i], sudoku[6][i], sudoku[7][i], sudoku[8][i]];
        if (column.sort().join('') !== '123456789') {
            errors = [...errors, ...[`0${i}`, `1${i}`, `2${i}`, `3${i}`, `4${i}`, `5${i}`, `6${i}`, `7${i}`, `8${i}`]]
        }
        let pear = pears[i];
        let box = [sudoku[pear[0][0]][pear[0][1]], sudoku[pear[1][0]][pear[1][1]], sudoku[pear[2][0]][pear[2][1]], sudoku[pear[3][0]][pear[3][1]], sudoku[pear[4][0]][pear[4][1]], sudoku[pear[5][0]][pear[5][1]], sudoku[pear[6][0]][pear[6][1]], sudoku[pear[7][0]][pear[7][1]], sudoku[pear[8][0]][pear[8][1]]];
        if (box.sort().join('') !== '123456789') {
            errors = [...errors, ...pear]
        }
    })
    console.log(errors)
    return errors
}


import React, {useState} from 'react';
// import Modal from 'react-modal';

const SetDifficultyModal = () => {
    const [active, setActive] = useState(false);

    return (
        <div className="centered-link" >
            <div className="modal-button" onClick={() => setActive(true)}>Set difficulty</div>
            {/* <Modal
                isOpen={active}
                onRequestClose={() =>setActive(false)}
                className="select-reactions-modal-aaaaaaa"
            >
                <div className="modal-container">
                    <div className="modal-title">'title'</div>
                    <div className="modal-content">
                        <p>desc</p>
                        <button className="grey-button" onClick={() => setActive(false)}>Cancel</button>
                    </div>
                </div>
            </Modal> */}
        </div>
    )
}

export default SetDifficultyModal
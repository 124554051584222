const React = require('react')


class Timer extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      time: 0,
      start: 0,
      isOn: false
    }
    this.startTimer = this.startTimer.bind(this)
    this.stopTimer = this.stopTimer.bind(this)
    this.resetTimer = this.resetTimer.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.props;
    if (status !== prevProps.status) {
        if(status === 'ideal') this.resetTimer();
        if(status === 'win' || status === 'lose') this.stopTimer();
        if(status === 'started' || status === 'restarted') {
            this.resetTimer();
            this.startTimer();
        }
    }
}
componentWillUnmount(){
    this.stopTimer();
}
  startTimer = ()=>  {
    this.setState({
      time: this.state.time,
      start: Date.now() - this.state.time,
      isOn: true
    })
    this.timer = setInterval(() => this.setState({
        time: Math.floor((Date.now() - this.state.start) / 1000)
    }), 1000);
  }
  stopTimer = ()=>  {
    this.setState({isOn: false})
    clearInterval(this.timer)
  }
  resetTimer = ()=> {
    this.setState({time: 0})
  }
  render() {
    // let start = (this.state.time == 0) ?
    //   <button onClick={this.startTimer}>start</button> :
    //   null
    // let stop = (this.state.isOn) ?
    //   <button onClick={this.stopTimer}>stop</button> :
    //   null
    // let reset = (this.state.time != 0 && !this.state.isOn) ?
    //   <button onClick={this.resetTimer}>reset</button> :
    //   null
    // let resume = (this.state.time != 0 && !this.state.isOn) ?
    //   <button onClick={this.startTimer}>resume</button> :
    //   null
    return(
      <span>{this.state.time}</span>
    )
  }
}
export default Timer
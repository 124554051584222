import { YoutubeSearchedForTwoTone } from "@material-ui/icons";

let x_sibling = [];
let y_sibling = [];

export function generateKakuro(row, column, difficulty) {
    const matrix = createMatrix(row, column);
    const numberMatrix = generateNumers(matrix, difficulty);
    const siblingsMatrix = findSiblings(numberMatrix);
    return siblingsMatrix
}


const shuffled = (str) => str.split('').sort(function () { return 0.5 - Math.random() }).join('');

function createMatrix(x, y) {
    x_sibling = [];
    y_sibling = [];
    let matrix = [];
    let mainArray = [...new Array(x * y).fill(null).map(() => shuffled('123456789'))];
    for (let i = 0; i < y; i++) {
        matrix.push(mainArray.splice(0, x))
    }
    return matrix
}

function generateNumers(matrix, difficulty) {
    for (let x = 0; x < matrix.length; x++) {
        for (let y = 0; y < matrix[0].length; y++) {
            if (matrix[x][y]) {
                // console.log('matrix[x][y] = ', matrix[x][y])
                let new_number = matrix[x][y][0];
                matrix[x][y] = new_number;
                // console.log('new_number = ', new_number);
                matrix = removeNumber(matrix, (Math.floor(Math.random() * difficulty) + 1), new_number, x, y)
                // console.log('matrix = ', JSON.parse(JSON.stringify(matrix)))
            }
        }
    }
    return matrix
}

function removeNumber(matrix, difficulty, new_number, i, ii) {
    difficulty = `${i}${i}` === '00' && difficulty < 3 ? 3 : difficulty;
    // console.log('x, y = ', i, ', ', ii)
    // console.log('difficulty = ', difficulty)
    let diff_right = difficulty;
    let diff_down = difficulty;
    for (let diff = 1; diff <= difficulty; diff++) {
        if (diff_right) {
            if (matrix[i] && matrix[i][ii + diff]) {
                let index_x = `${i}${ii + diff}`;

                if (diff_right === 1) {
                    matrix[i][ii + diff] = '';
                    diff_right = 0;
                }
                else if (x_sibling.includes(index_x)) {
                    matrix[i][ii + diff] = matrix[i][ii + diff].replace(new_number, '')
                }
                else {
                    x_sibling.push(index_x)
                    matrix[i][ii + diff] = matrix[i][ii + diff].replace(new_number, '')
                    diff_right--;
                }
            }
            else {
                diff_right = 0;
            }
        }

        if (diff_down) {
            if (matrix[i + diff] && matrix[i + diff][ii]) {
                let index_y = `${i + diff}${ii}`;

                if (diff_down === 1) {
                    matrix[i + diff][ii] = '';
                    diff_down = 0;
                }
                else if (y_sibling.includes(index_y)) {
                    matrix[i + diff][ii] = matrix[i + diff][ii].replace(new_number, '')
                }
                else {
                    y_sibling.push(index_y)
                    matrix[i + diff][ii] = matrix[i + diff][ii].replace(new_number, '')
                    diff_down--;
                }
            }
            else {
                diff_down = 0;
            }
        }



    }

    return matrix
}


function findSiblings(numberMatrix) {
    let siblings = [];
    let x_largest = 0;
    let y_largest = 0;
    const queue = [];


    function removeFromQueue(index) {
        if (numberMatrix[index[0]] && numberMatrix[index[0]][index[1]]) {
            const number = numberMatrix[index[0]][index[1]];
            if (number && !siblings.includes(index)) {
                let x_number = parseInt(index[0]);
                let y_number = parseInt(index[1]);
                if (x_number > x_largest) x_largest = x_number
                if (y_number > y_largest) y_largest = y_number
                siblings.push(index)
                queue.unshift(`${x_number + 1}${index[1]}`)
                if (x_number > 0) queue.unshift(`${x_number - 1}${index[1]}`)
                queue.unshift(`${x_number}${y_number + 1}`)
                if (y_number > 0) queue.unshift(`${x_number}${y_number - 1}`)
            }
        }
        const letssee = queue.pop();
        if (letssee) removeFromQueue(letssee)
    }


    removeFromQueue('00')

    console.log(siblings)
    console.log('x_largest = ', x_largest)
    console.log('y_largest = ', y_largest)
    console.log(queue)


    return removeUntouchables(numberMatrix, siblings, x_largest, y_largest)
}

function removeUntouchables(matrix, siblings, x_largest, y_largest) {
    console.log('removeUntouchables matrix = ', JSON.parse(JSON.stringify(matrix)))
    let removedMatrix = [new Array(y_largest + 2).fill('')];


    for (let x = 0; x < matrix.length; x++) {
        for (let y = 0; y < matrix[0].length; y++) {
            if (!siblings.includes(`${x}${y}`)) matrix[x][y] = ''
        }
    }
    for (let x = 0; x < x_largest + 1; x++) {
        removedMatrix.push(['', ...matrix[x].splice(0, y_largest + 1)])
    }
    console.log('removedMatrix matrix = ', JSON.parse(JSON.stringify(removedMatrix)))

    return removeCombinations(removedMatrix)
}

function removeCombinations(matrix) {
    for (let x = 0; x < matrix.length; x++) {
        for (let y = 0; y < matrix[0].length; y++) {
            if (!matrix[x][y]) {
                let new_data = {
                    right: 0,
                    down: 0
                };
                if (matrix[x][y + 1]) {
                    new_data.right = combineRight(matrix[x], y + 1)
                }
                if (matrix[x + 1] && matrix[x + 1][y]) {
                    new_data.down = combineDown(matrix, x + 1, y)
                }
                matrix[x][y] = new_data
            }
        }
    }
    return matrix
}

function combineRight(arr, yy) {
    let zbroj = 0;
    for (let y = yy; y < arr.length; y++) {
        if (arr[y]) zbroj += parseInt(arr[y])
        else break
    }
    return zbroj
}

function combineDown(matrix, xx, y) {
    let zbroj = 0;
    for (let x = xx; x < matrix.length; x++) {
        if (matrix[x] && matrix[x][y]) zbroj += parseInt(matrix[x][y])
        else break
    }
    return zbroj
}
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { getSudoku, pears, checkIfGameIsGood } from '../../helpers/sudokuHelpers';
import TimerTwo from '../Timer/TimerTwo';
import SetDifficultyModal from './Modal';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import './Sudoku.scss';
import Settings from './Settings';
import NewGamePopover from './NewGamePopover';
import YouWonDialog from './YouWonDialog';
import {toHHMMSS} from '../../helpers/timerHelpers';
import { Drawer, Button, Checkbox, FormControlLabel, Popover, FormControl, Select } from '@material-ui/core';

const Sudoku = () => {
    const [difficulty, setDifficulty] = useState(15);
    const [sudoku, setSudoku] = useState([]);
    const [sudoku_game, setSudoku_game] = useState([]);
    const [selected, setSelected] = useState('');
    const [selected_number, setSelected_number] = useState('');
    const [grayed_out, setGrayed_out] = useState([]);
    const [errors, setErrors] = useState([]);
    const [game_status, setGame_status] = useState('ideal');
    const [drawer, setDrawer] = useState(false);
    const [hide_timer, setHide_timer] = useState(false);
    const [new_game_open, setNew_game_open] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [time, setTime] = useState(0);



    useEffect(() => {
        createGame(difficulty)
    }, [])

    useEffect(() => {
        if (selected) {
            let grayed_out_array = [...pears.filter((a, b) => a.includes(selected))[0]];
            for (let i = 0; i < 9; i++) {
                grayed_out_array.push(`${selected[0]}${i}`)
                grayed_out_array.push(`${i}${selected[1]}`)
            }
            setGrayed_out(grayed_out_array)
        }
    }, [selected])

    const changeSelected = (new_select) => {
        setErrors([]);
        setSelected_number(sudoku_game[new_select[0]][new_select[1]]);
        setSelected(new_select)
    }

    const handleKeyDown = useCallback(event => {
        if (selected) {
            if (event.keyCode > 48 && event.keyCode < 58) {
                numberClicked((event.keyCode - 48).toString())
            }
            else if (event.keyCode === 8) {
                numberClicked('')
            }
            else if (event.keyCode === 39) {
                changeSelected(`${selected[0]}${selected[1] === '8' ? '0' : (parseInt(selected[1]) + 1)}`)
            }
            else if (event.keyCode === 37) {
                changeSelected(`${selected[0]}${selected[1] === '0' ? '8' : (parseInt(selected[1]) - 1)}`)
            }
            else if (event.keyCode === 40) {
                changeSelected(`${selected[0] === '8' ? '0' : (parseInt(selected[0]) + 1)}${selected[1]}`)
            }
            else if (event.keyCode === 38) {
                changeSelected(`${selected[0] === '0' ? '8' : (parseInt(selected[0]) - 1)}${selected[1]}`)
            }
            console.log(event.keyCode)
        }
    }, [selected, setSelected, setSelected_number]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);


    const createGame = (diff) => {
        let new_sudoku = getSudoku(diff);
        setSudoku_game(JSON.parse(JSON.stringify(new_sudoku)))
        setSudoku(Object.assign([], new_sudoku))
        setErrors([])
        setSelected_number()
        setSelected()
        setGame_status(game_status === 'started' ? 'restarted' : 'started')
        setDifficulty(diff)
        window.dataLayer.push({
            'sudoku_game': 'created',
            'sudoku_difficulty': diff
        })
    }

    const numberClicked = (number) => {
        if (selected) {
            setSelected_number(number)
            sudoku_game[selected[0]][selected[1]] = number;
            setSudoku_game(sudoku_game)
            if (number && sudoku_game.flat(1).join('').length === 81) {
                let error = checkIfGameIsGood(sudoku_game);
                setErrors(error);
                setSelected('');
                setSelected_number('');
                setGrayed_out([]);
                if (error.length > 0) {
                    console.log('there are errors', error)
                }
                else {
                    console.log('you won')
                    window.dataLayer.push({
                        'sudoku_game': 'won',
                        'sudoku_timer': 123
                    })
                    setGame_status('win')
                }
            }
        }
    }


    return (
        <div className="sudoku-wrapper">
            <Drawer className="settings-drawer" anchor={'right'} open={drawer} onClose={() => setDrawer(false)}>
                <Settings />
            </Drawer>
            {game_status === 'win' ? <YouWonDialog status={game_status} difficulty={difficulty} createGame={createGame} />: ''}
            <div className="game-header">
                <NewGamePopover difficulty={difficulty} createGame={createGame} />


                {!hide_timer && <div className="sudoku-timer"><TimerIcon /> <TimerTwo status={game_status} time={time} setTime={setTime} convert={(t)=>toHHMMSS(t)}/></div>}

                <Button type="button" onClick={() => setDrawer(!drawer)}><SettingsIcon /></Button>

            </div>
            <div className="sudoku-container">
                {sudoku.map((row, ix) => {
                    return (
                        <Fragment key={'fragment-' + ix}>
                            {row.map((cell, iy) => {
                                return (
                                    <div className={`cell ${!cell ? 'mutable' : ''} row-${ix} column-${iy} ${grayed_out.includes(`${ix}${iy}`) ? 'grayed-out' : ''} ${errors.includes(`${ix}${iy}`) ? 'error' : ''} ${selected === `${ix}${iy}` ? 'selected' : ''}`}
                                        key={'cell-' + ix + iy}
                                    // onClick={e => this.handleClick(e, ix, iy)}
                                    // onContextMenu={(e) => this.handleClick(e, ix, iy)}
                                    >

                                        {cell ? cell :
                                            <div className="input-cell"
                                                onClick={() => { changeSelected(`${ix}${iy}`) }}
                                            >{selected === `${ix}${iy}` ? selected_number : sudoku_game[ix][iy]}</div>
                                        }

                                    </div>
                                )
                            })}
                            {/* <div className="separator" key={'separator-' + ix}></div> */}
                        </Fragment>
                    )
                })}

            </div>
            <div className="sudoku-footer">
                {errors.length > 0 ? <div className="sudoku-errors">
                    <span /> Errors
                    <span /> Affected area
                </div> : ''}
                {errors.length < 1 && !!selected ? <div className="sudoku-numbers">
                    {'123456789'.split('').map((a) => <div className={selected_number === a ? 'selected' : ''} key={a} onClick={() => { numberClicked(a) }}>{a}</div>)}
                    <div onClick={() => { numberClicked('') }}>X</div>
                </div> : ''}
            </div>

        </div>
    )
}

export default Sudoku
import { combineReducers } from 'redux';
// import { systemReducer } from './system/reducers'
// import { chatReducer } from './chat/reducers'
import settingsReducer from './settings/reducers'



const rootReducer = combineReducers({
  // system: systemReducer,
  // chat: chatReducer,
  settings: settingsReducer
})


export function createReducer() {
  return rootReducer
};


// export type RootState = ReturnType<typeof rootReducer>


export function createMatrixObject(x, y, mines) {
    const matrix = createMatrix(x, y, mines);
    const posibilityMatrix = checkMatrix(matrix);
    const final = combineMatrix(matrix, posibilityMatrix);
    return final
}


function createMatrix(x, y, mines) {
    let matrix = [];
    let mainArray = [...new Array(mines).fill(true), ...new Array(((x * y) - mines)).fill(false)];
    for (let i = 0; i < y; i++) {
        mainArray = shuffle(mainArray);
        matrix.push(mainArray.splice(0, x))
    }
    return matrix
}


function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}

function checkMatrix(matrix) {
    let final = [];
    for (let x = 0; x < matrix.length; x++) {
        let row = [];
        for (let y = 0; y < matrix[x].length; y++) {
            let cuerrent_surounding = 0;
            cuerrent_surounding += checkmine(matrix, x - 1, y - 1);
            cuerrent_surounding += checkmine(matrix, x - 1, y);
            cuerrent_surounding += checkmine(matrix, x - 1, y + 1);
            cuerrent_surounding += checkmine(matrix, x, y - 1);
            cuerrent_surounding += checkmine(matrix, x, y + 1);
            cuerrent_surounding += checkmine(matrix, x + 1, y - 1);
            cuerrent_surounding += checkmine(matrix, x + 1, y);
            cuerrent_surounding += checkmine(matrix, x + 1, y + 1);
            row.push(cuerrent_surounding)
        }
        final.push(row)
    }
    return final
}
function checkmine(matrix, x, y) {
    if (matrix[x] !== undefined) {
        if (matrix[x][y] !== undefined) {
            return matrix[x][y] ? 1 : 0
        }
    }
    return 0
}


function combineMatrix(matrix, posibilityMatrix){
    let final = [];
    for (let x = 0; x < matrix.length; x++) {
        let row = [];
        for (let y = 0; y < matrix[x].length; y++) {
            row.push({mine: matrix[x][y], posibility: posibilityMatrix[x][y], flag: false, clicked: false})
        }
        final.push(row)
    }
    return final
}

export function checkIfUserWon(matrix){
    for (let x = 0; x < matrix.length; x++) {
        for (let y = 0; y < matrix[x].length; y++) {
            if(!matrix[x][y].mine && !matrix[x][y].clicked) return false
        }
    }
    return true
}
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { getSudoku, pears, checkIfGameIsGood } from '../../helpers/sudokuHelpers';
import TimerTwo from '../Timer/TimerTwo';
import SetDifficultyModal from './Modal';
import TimerIcon from '@material-ui/icons/Timer';
import { Drawer, Button, Checkbox, FormControlLabel, Popover, FormControl, Select } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { SketchPicker, SwatchesPicker } from 'react-color';
import { updateSettings, removeSettings } from '../../store/settings/actions';
import { shallowEqual, useSelector } from 'react-redux'
import './Settings.scss';

const Settings = () => {
    const settings = useSelector((state) => state.settings, shallowEqual) || null;
    const [hide_timer, setHide_timer] = useState(false);
    const [color_open, setColor_open] = useState(false);
    const [background, setBackground] = useState('#ffffff');
    const [size, setSize] = useState('medium');
    const [anchorEl, setAnchorEl] = useState(null);


    const handleKeyDown = useCallback(event => {
        setColor_open(false)
        console.log(event.keyCode)
    }, [setColor_open, color_open]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown, setColor_open, color_open]);

    return (
        <div className="settings-wrapper">
            <div className="settings-title">Background</div>
            <div>
                <div className='color-placeholder' onClick={(e) => { setAnchorEl(e.currentTarget); setColor_open(!color_open) }}><span style={{backgroundColor: settings.background ?  settings.background : '#ffffff'}}/>Background Color</div>
                <Popover
                    open={color_open}
                    anchorEl={anchorEl}
                    onClose={() => setColor_open(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <SwatchesPicker color={settings.background} onChangeComplete={(color) => updateSettings({ background: color.hex })} />
                </Popover>

            </div>
            <div className="settings-title">Size</div>
            <div>
                <FormControl variant="outlined">
                    <Select
                        native
                        value={settings.size}
                        onChange={(e, value) => updateSettings({ size: value })}
                    >
                        <option value={'small'}>Small</option>
                        <option value={'medium'}>Medium</option>
                        <option value={'big'}>Big</option>
                    </Select>
                </FormControl>
            </div>

            <div className="settings-title">Timer</div>
            <div>
                <FormControlLabel label={`Hide timer`} control={<Checkbox checked={settings.hide_timer} onChange={() => updateSettings({ hide_timer: !settings.hide_timer })} />} />
            </div>
            <Button variant="contained" color="primary" onClick={(e) => { removeSettings(); setColor_open(false) }}>Reset to Default</Button>
        </div>
    )
}

export default Settings
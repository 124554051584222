import React from 'react';
import Minesweeper from '../../components/Minesweeper/Minesweeper';
import { shallowEqual, useSelector } from 'react-redux'
import './Minesweeper.scss';

const MinesweeperPage = () => {
    const settings = useSelector((state) => state.settings, shallowEqual) || null;

    return (
        <div className={`page-container size-${settings.size} hide-${settings.hide_timer ? 'timer' : ''}`} style={{backgroundColor: settings.background ?  settings.background : '#ffffff'}}>
            <Minesweeper />
        </div>
    )
}

export default MinesweeperPage
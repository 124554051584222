import React, { Fragment, useEffect, useState, useCallback } from 'react';

const TimerTwo = ({ status, convert }) => {
  const [time, setTime] = useState(0);
  const [start, setStart] = useState(Date.now());
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isOn) {
        setTime(Math.floor((Date.now() - start) / 1000))
      }
      else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isOn, start]);


  useEffect(() => {
    if (status === 'ideal') resetTimer();
    else if (status === 'win' || status === 'lose') {
      stopTimer();
    }
    else if (status === 'started' || status === 'restarted') {
      startTimer();
    }
  }, [status])

  const startTimer = () => {
    setTime(0);
    setIsOn(true);
    setStart(Date.now());
  }

  const stopTimer = () => {
    setIsOn(false);
  }
  const resetTimer = () => {
    setTime(0);
    setIsOn(false);
    setStart(Date.now());
  }

  return convert(time)
}


export default TimerTwo
import React, { useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Rotate from 'react-reveal/Rotate';
import { shallowEqual, useSelector } from 'react-redux'
import './HamburgerMenu.scss';


const HamburgerMenu = () => {
  const settings = useSelector((state) => state.settings, shallowEqual) || null;
  const [active, setActive] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const toggleMenu = () => {
    setActive(!active);
  }

  return (
    <Fragment>

      <div className={`header-container ${collapsed ? 'collapsed' : ''}`}>
        <div className="container">

          <Helmet>
            <html className={`${active ? ' menu-active ' : ''}`} />
          </Helmet>
          <NavLink to={`/`} className="header-logo" onClick={() => setActive(false)}  style={{color: settings.background ?  settings.background : '#ffffff'}}>
            <span>O</span>
            <span>J</span>
            <span>I</span>
            <span>R</span>
            <span>A</span>
            <span>M</span>
          </NavLink>
          <div className={`hamburger-container ${active ? ' change ' : ''}`} onClick={() => { toggleMenu() }} >
            <div className="bar1"  style={{backgroundColor: settings.background ?  settings.background : '#ffffff'}}></div>
            <div className="bar2"  style={{backgroundColor: settings.background ?  settings.background : '#ffffff'}}></div>
            <div className="bar3"  style={{backgroundColor: settings.background ?  settings.background : '#ffffff'}}></div>
          </div>

        </div>
      </div>

      <div className={`menu-container ${active ? ' active ' : ''}`}>
        <div className="menu-links">
          <Rotate when={active} delay={400}>
            <NavLink onClick={toggleMenu} to={`/minesweeper`}><span>Minesweeper</span></NavLink>
          </Rotate>
          <Rotate when={active} delay={300}>
            <NavLink onClick={toggleMenu} to={`/sudoku`}><span>Sudoku</span></NavLink>
          </Rotate>
          <Rotate when={active} delay={200}>
            <NavLink onClick={toggleMenu} to={`/kakuro`}><span>Kakuro</span></NavLink>
          </Rotate>
          <Rotate when={active} delay={100}>
            <NavLink onClick={toggleMenu} to={`/tetris`}><span>Tetris</span></NavLink>
          </Rotate>
          <Rotate when={active} delay={0}>
            <NavLink onClick={toggleMenu} to={`/rokos-adventure`}><span>Roko's Adventure</span></NavLink>
          </Rotate>



          {/*

            <Rotate when={active} delay={100}>
              <NavLink onClick={this.toggleMenu} to={`/tracking`}><span>{t('tracking')}</span></NavLink>
            </Rotate>
            <Rotate when={active} delay={0}>
              <NavLink onClick={this.toggleMenu} to={`/portfolio`}><span>{t('portfolio')}</span></NavLink>
            </Rotate> */}
        </div>
      </div>
    </Fragment>
  );
}


export default HamburgerMenu;
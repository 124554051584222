// import { default_ojiram_settings } from './actions';

export default function identityReducer(
  state = {},
  action = {}
) {
  switch (action.type) {
    case 'SET_SETTINGS':
      return action.payload
    default:
      return state
  }
};
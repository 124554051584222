import React from 'react';
import { shallowEqual, useSelector } from 'react-redux'
import './RokosAdventure.scss';

const RokosAdventure = () => {
    const settings = useSelector((state) => state.settings, shallowEqual) || null;
    // const [full_screen, setFullScreen] = useState(false);


    // const resize = () => {

    // }

    // useEffect(() => {
    //     resize();
    // }, [full_screen, resize]);

    return (
        <div className={`page-container size-${settings.size} hide-${settings.hide_timer ? 'timer' : ''}`} style={{ backgroundColor: settings.background ? settings.background : '#ffffff' }}>
            <div className="rokos-adventure">
                <iframe src="https://dsf74q1eqdgi5.cloudfront.net" title="Rokos adventure"></iframe>
            </div>
        </div>
    )
}

export default RokosAdventure
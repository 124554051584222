import React, { Fragment, useEffect, useState, useCallback, createRef, useRef } from 'react';
import { createMatrixObject, checkIfUserWon } from '../../helpers/minesweeperHelpers';
import Timer from '../Timer/Timer';
import NewGamePopover from './NewGamePopover';
import Settings from '../Settings/Settings';
import TimerTwo from '../Timer/TimerTwo';
import FireworksComp from '../Fireworks/Fireworks';

import './Minesweeper.scss';


const Minesweeper = () => {
    const [vectors, setVectors] = useState({
        columns: '10',
        rows: '10',
        mines: '10'
    });
    const [matrix, setMatrix] = useState([]);
    const [flags, setFlags] = useState(0);
    const [game_over, setGame_over] = useState(false);
    const [next_click_flag, setNext_click_flag] = useState(false);
    const [game_status, setGame_status] = useState('ideal');
    const [time, setTime] = useState(0);

    const minesweeperContainerRef = useRef(null);

    useEffect(() => {
        minesweeperContainerRef.current.addEventListener('contextmenu', function (e) {
            e.preventDefault();
        })
        return () => {
            minesweeperContainerRef.current.removeEventListener('contextmenu', function (e) {
                e.preventDefault();
            })
        };
    }, [minesweeperContainerRef]);


    useEffect(() => {
        tryCreateMatrix()
    }, [vectors]);


    const tryCreateMatrix = () => {
        const new_matrix = createMatrixObject(parseInt(vectors.columns), parseInt(vectors.rows), parseInt(vectors.mines));
        setMatrix(new_matrix);
        setGame_over(false);
        setGame_status('ideal');
        setFlags(parseInt(vectors.mines));
    }

    const createGame = (columns, rows, mines) => {
        setVectors({ columns, rows, mines })
    }

    const handleClick = (e, x, y) => {
        if (!game_over) {
            if (game_status === 'ideal') startGame();
            const current_matrix = [...matrix];
            const cell = current_matrix[x][y];
            if (e.type === 'contextmenu' || next_click_flag ) {
                if (!cell.clicked) {
                    setNext_click_flag(false)
                    current_matrix[x][y].flag = !current_matrix[x][y].flag;
                    setMatrix(current_matrix)
                    setFlags(!current_matrix[x][y].flag ? flags + 1 : flags - 1)
                }
            }
            else if (e.type === 'click') {
                if (!cell.flag && !cell.clicked) {
                    if (cell.mine) {
                        current_matrix[x][y].losingCell = true;
                        gameOver();
                    }
                    else rippleEffect(current_matrix, x, y);
                }
            }
        }
    }


    const rippleEffect = (current_matrix, x, y) => {
        const maxRow = current_matrix.length;
        const maxCol = current_matrix[0].length;
        const queue = [];

        function removeFromQueue(row, col) {
            const cell = current_matrix[row][col];
            if (!current_matrix[row][col].flag) {
                current_matrix[row][col].clicked = true;
            }
            if (cell.posibility === 0) {
                checkNeighbours(row - 1, col);
                checkNeighbours(row, col - 1);
                checkNeighbours(row + 1, col);
                checkNeighbours(row, col + 1);

                checkNeighbours(row - 1, col - 1);
                checkNeighbours(row + 1, col - 1);
                checkNeighbours(row - 1, col + 1);
                checkNeighbours(row + 1, col + 1);
            }

            const letssee = queue.pop();
            if (letssee) removeFromQueue(letssee[0], letssee[1])
        }

        function checkNeighbours(row, col) {
            if (row >= 0 && row < maxRow && col >= 0 && col < maxCol) {
                if (!current_matrix[row][col].clicked) {
                    if (!current_matrix[row][col].flag) current_matrix[row][col].clicked = true;
                    if (current_matrix[row][col].posibility === 0) {
                        queue.unshift([row, col])
                    }
                }
            }
        }
        removeFromQueue(x, y)

        setMatrix(current_matrix)
        if (checkIfUserWon(current_matrix)) {
            setGame_over(true);
            setGame_status('win');
        }
    }


    const gameOver = () => {
        setGame_over(true);
        setGame_status('lose');
    }

    const startGame = () => {
        setGame_status('started');
    }


    return (
        <div className="minesweeper-wrapper">
            {game_status === 'win' ? <FireworksComp /> : ''}
            <div className="intro-block-wrapper">
                <div className="intro-block">
                    <NewGamePopover columns={vectors.columns} rows={vectors.rows} mines={vectors.mines} createGame={createGame} />
                    <Settings />
                </div>
            </div>

            <div className={`minesweeper-container-outside ${game_status === 'win' ? 'game-won' : ''}`} style={{ width: (parseInt(vectors.columns) * 21) + 15 }}>
                <div className="minesweeper-header">
                    <div className="header-flags">{flags}</div>
                    <div className={`header-status ${game_status}`} onClick={() => tryCreateMatrix()}></div>
                    {/* <div className="header-time">{time}</div> */}
                    {/* <div className="header-time"><Timer status={game_status} /> <TimerTwo status={game_status} time={time} setTime={setTime} convert={(t)=>t}/></div> */}
                    <div className="header-time"><TimerTwo status={game_status} time={time} setTime={setTime} convert={(t) => t} /></div>
                </ div>
                <div className={`minesweeper-container `} ref={minesweeperContainerRef}>
                    {matrix.map((row, ix) => {
                        return (
                            <Fragment key={'fragment-' + ix}>
                                {row.map((cell, iy) => {
                                    return (
                                        <div className={`cell 
                                            ${cell.flag ? 'flag' : ''} 
                                            ${game_over && cell.flag && !cell.mine ? 'wrong-flag' : ''} 
                                            ${game_over && cell.mine ? 'mine' : ''} 
                                            ${game_over && cell.losingCell ? 'losing-cell' : ''} 
                                            ${ix === 0 ? 'border-top' : ''}
                                             ${iy === 0 ? 'border-left' : ''}`}
                                            key={'cell-' + ix + iy} onClick={e => handleClick(e, ix, iy)}
                                            onContextMenu={(e) => handleClick(e, ix, iy)}><span className={`posibility ${cell.clicked ? 'clicked' : ''} ${cell.clicked && cell.posibility ? `posibility-color-${cell.posibility}` : ''} `}>{cell.clicked && cell.posibility ? cell.posibility : ''}</span></div>
                                    )
                                })}
                                <div className="separator" key={'separator-' + ix}></div>
                            </Fragment>
                        )
                    })}
                </div>


            </div>

            <div className="minesweeper-footer">
                   {game_status !== 'win' && <div className={`minesweeper-flag ${next_click_flag ? 'selected' : ''}`} onClick={() => setNext_click_flag(!next_click_flag)}>
                        <span />
                    </div>}
                </div>

        </div>
    )

}

export default Minesweeper
import React, { Fragment, useEffect, useState, useCallback, createRef, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux'
import { generateTetris } from '../../helpers/tetrisHelpers';
import NewGamePopover from './NewGamePopover';
import isObject from 'lodash/isObject';

import './Tetris.scss';

const Tetris = () => {
    const settings = useSelector((state) => state.settings, shallowEqual) || null;
    const [vectors, setVectors] = useState({
        columns: '9',
        rows: '9',
        difficulty: '4'
    });
    const [game_status, setGame_status] = useState('ideal');
    const [tetris, setTetris] = useState([
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false],
        [false,false,false,false,false,false,false,false,false,false,false]
    ]);
    const [tetris_game, setTetris_game] = useState([]);
    const [selected, setSelected] = useState('');
    const [selected_number, setSelected_number] = useState('');

    useEffect(() => {
        // tryCreateMatrix()
    }, [vectors]);


    const tryCreateMatrix = () => {
        const new_tetris = generateTetris(parseInt(vectors.columns), parseInt(vectors.rows), parseInt(vectors.difficulty));
        setTetris_game(JSON.parse(JSON.stringify(new_tetris)))
        setTetris(Object.assign([], new_tetris))
        setGame_status('ideal');
    }

    const createGame = (columns, rows, difficulty) => {
        setVectors({ columns, rows, difficulty })
    }


    const changeSelected = (new_select) => {
        // setErrors([]);
        // setSelected_number(tetris_game[new_select[0]][new_select[1]]);
        // setSelected(new_select)
    }

    return (









        <div className="tetris-wrapper">
            {/* <Drawer className="settings-drawer" anchor={'right'} open={drawer} onClose={() => setDrawer(false)}>
    <Settings />
</Drawer> */}
            <div className="game-header">
                <NewGamePopover columns={vectors.columns} rows={vectors.rows} difficulty={vectors.difficulty} createGame={createGame} />

            </div>
            <div className="tetris-container">
                {tetris.map((row, ix) => {
                    return (
                        <Fragment key={'fragment-' + ix}>
                            {row.map((cell, iy) => {
                                return (
                                    <div className={`cell ${!cell ? 'mutable' : ''} row-${ix} column-${iy} ${isObject(cell) ? 'tetris-cell-placeholder' : 'tetris-input-cell'} ${selected === `${ix}${iy}` ? 'selected' : ''}`}
                                        key={'cell-' + ix + iy}
                                    // onClick={e => this.handleClick(e, ix, iy)}
                                    // onContextMenu={(e) => this.handleClick(e, ix, iy)}
                                    >

                                        {isObject(cell)? 
                                        <div className="tetris-placeholder" style={{backgroundColor: settings.background ?  settings.background : '#ffffff'}}>
                                           {cell.right ? <div className="tetris-placeholder-right"><span>{cell.right}</span></div> :''}
                                           {cell.down ? <div className="tetris-placeholder-down"><span>{cell.down}</span></div> :''}
                                        </div>
                                        
                                        :
                                            <div className="input-cell"
                                                onClick={() => { changeSelected(`${ix}${iy}`) }}
                                            >{selected === `${ix}${iy}` ? selected_number : tetris_game[ix][iy]}</div>
                                        }

                                    </div>
                                )
                            })}
                            <div className="separator" key={'separator-' + ix}></div>
                        </Fragment>
                    )
                })}

            </div>
            <div className="tetris-footer">
                {/* {errors.length > 0 ? <div className="tetris-errors">
        <span /> Errors
        <span /> Affected area
    </div> : ''}
    {errors.length < 1 && !!selected ? <div className="tetris-numbers">
        {'123456789'.split('').map((a) => <div className={selected_number === a ? 'selected' : ''} key={a} onClick={() => { numberClicked(a) }}>{a}</div>)}
        <div onClick={() => { numberClicked('') }}>X</div>
    </div> : ''} */}
            </div>
        </div>




    )
}

export default Tetris
import React, { useState, useEffect } from 'react';
// import Modal from 'react-modal';
import './Fireworks.scss';
const FireworksComp = () => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        const container = document.querySelector('.fireworks-container')
        // const container_two = document.querySelector('.fireworks-container-two')
        const fireworks = new window.Fireworks({
            target: container,
            hue: 120,
            startDelay: 1,
            minDelay: 20,
            maxDelay: 30,
            speed: 4,
            acceleration: 1.05,
            friction: 0.98,
            gravity: 1,
            particles: 75,
            trace: 3,
            explosion: 5,
            boundaries: {
                top: 50,
                bottom: container.clientHeight,
                left: 50,
                right: container.clientWidth
            },
            sound: {
                enable: false,
                list: [
                    'explosion0.mp3',
                    'explosion1.mp3',
                    'explosion2.mp3'
                ],
                min: 4,
                max: 8
            }
        })

        // const fireworks_two = new window.Fireworks({
        //     target: container_two,
        //     hue: 120,
        //     startDelay: 1,
        //     minDelay: 20,
        //     maxDelay: 30,
        //     speed: 4,
        //     acceleration: 1.05,
        //     friction: 0.98,
        //     gravity: 1,
        //     particles: 75,
        //     trace: 3,
        //     explosion: 5,
        //     boundaries: {
        //         top: 50,
        //         bottom: container_two.clientHeight,
        //         left: 50,
        //         right: container_two.clientWidth
        //     },
        //     sound: {
        //         enable: false,
        //         list: [
        //             'explosion0.mp3',
        //             'explosion1.mp3',
        //             'explosion2.mp3'
        //         ],
        //         min: 4,
        //         max: 8
        //     }
        // })

        // start fireworks
        fireworks.start()
        // fireworks_two.start()

    }, [])
    return (
        <div className="fireworks-wrapper" >
            <div className="fireworks-container" style={{ backgroundSize: 'cover', backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat' }}></div>
            {/* <div className="fireworks-container-two" style={{ backgroundSize: 'cover', backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat' }}></div> */}
        </div>
    )
}

export default FireworksComp
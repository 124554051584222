import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from './pages/Home/Home';
import Minesweeper from './pages/Minesweeper/Minesweeper';
import Sudoku from './pages/Sudoku/Sudoku';
import RokosAdventure from './pages/RokosAdventure/RokosAdventure';
import Kakuro from './pages/Kakuro/Kakuro';
import Tetris from './pages/Tetris/Tetris';
import HamburgerMenu from './components/HamburgerMenu/HamburgerMenu';

// import './App.scss';
import './fonts.scss';

import './styles/foundation.css';
import './styles/fonts.css';
// import './styles/App.scss';
import './styles/AppTwo.scss';
import './styles/Buttons.scss';
import './styles/modals.scss';
import './styles/arabic.scss';
import './styles/theme.scss';
import './styles/main-content.scss';
import './styles/header.scss';


import store from './store/store';
import { Provider } from 'react-redux';
import theme from './theme';
import { ThemeProvider } from '@material-ui/core';
import { getSettings } from './store/settings/actions';
getSettings();

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme} >
        <Router>
          <div className="main-container">
            <HamburgerMenu />
            <div className="main-content-back-one" ></div>
            <div className="main-content-back-two" ></div>
            <div className="main-content">

              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/minesweeper" component={Minesweeper} />
                <Route path="/sudoku" component={Sudoku} />
                <Route path="/rokos-adventure" component={RokosAdventure} />
                <Route path="/kakuro" component={Kakuro} />
                <Route path="/tetris" component={Tetris} />
              </Switch></div>
          </div>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;

{/* <div className="App">
<Router>
  <header className="App-header">
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
      <li>
        <Link to="/minesweeper">Minesweeper</Link>
      </li>
      <li>
        <Link to="/sudoku">Sudoku</Link>
      </li>
    </ul>
    <hr />
  </header>
</Router>
</div> */}
// import { makeStyles, CssBaseline, createMuiTheme, ThemeProvider } from '@material-ui/core';
// import { fontWeight } from '@material-ui/system';
import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5a7886',
      light: '#3c44b126'
    },
    secondary: {
      main: '#f83245',
      light: '#f8324526'
    },
    background: {
      default: '#f4f5fd'
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)'
      }
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      }
    },
    MuiFormControl: {
      root: {
        // width:'100%'
      }
    },
    MuiInputLabel: {
      root: {
        textTransform: 'capitalize'
      }
    },
  },
  typography: {
    // fontFamily: 'Roboto',
    fontSize: 14,
    h1: {
      fontSize: '2rem',
      fontWeight: 700
    },
    h2: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '1.2rem',
      fontWeight: 500
    }
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  props: {
    MuiCheckbox: {
      disableRipple: true
    },
    MuiIconButton: {
      disableRipple: true
    },
    MuiButton: {
      disableRipple: true,
      variant: 'contained',
      color: 'primary',
    },
    MuiTextField: {
      InputLabelProps: {
        shrink: true
      },
      color: 'primary'
    },
    MuiPaper: {
      elevation: 6
    },
    MuiCard: {
      elevation: 6
    },
    MuiSelect: {
      MenuProps: {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        }
      }
    }
  },
})

export default theme;
import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { getSudoku, pears, checkIfGameIsGood } from '../../helpers/sudokuHelpers';
import TimerTwo from '../Timer/TimerTwo';
import SetDifficultyModal from './Modal';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import './YouWonDialog.scss';
import Settings from './Settings';
import { Drawer, Button, Checkbox, FormControlLabel, Popover, FormControl, Select, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FireworksComp from '../Fireworks/Fireworks';

const YouWonDialog = ({ difficulty, createGame, status }) => {
    const [slider_difficulty, setSlider_difficulty] = useState(difficulty);
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            {status === 'win' ? <FireworksComp /> : ''}
            {status === 'win' ? <div className="you-won-dialog">
                <div>
                    <h1>
                        You won!!!
                </h1>

                    <Slider
                        className="slider-with-marker"
                        // ValueLabelComponent={ValueLabelComponent}
                        // ValueLabelComponent={ValueLabelComponent}
                        //  aria-labelledby="track-inverted-range-slider"
                        // valueLabelFormat
                        // ValueLabelComponent={}
                        track="inverted"
                        // defaultValue={filter.data.default_value}
                        //  valueLabelDisplay="on"
                        valueLabelDisplay="auto"
                        min={1}
                        max={80}
                        // onChangeCommitted={onChangeCommitted}
                        onChange={(e, value) => setSlider_difficulty(value)}
                        value={slider_difficulty}
                        // valueLabelFormat={filter.data.format}
                        // step={filter.data.step}
                        // marks={true}
                        step={1}
                    />
                    <Button onClick={(e) => { setOpen(false); createGame(slider_difficulty) }}>Play again</Button>
                </div>  </div> : ''}
        </Fragment>
    )
}

export default YouWonDialog
import React, { Fragment, useState } from 'react';
import { Button, Popover, Slider } from '@material-ui/core';

const Sudoku = ({ columns, rows, mines, createGame }) => {
    const [slider_columns, setSlider_columns] = useState(columns);
    const [slider_rows, setSlider_rows] = useState(rows);
    const [slider_mines, setSlider_mines] = useState(mines);
    const [new_game_open, setNew_game_open] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <Fragment>
            <div className={`new-game-button ${new_game_open ? 'selected' :''}`} onClick={(e) => { setAnchorEl(e.currentTarget); setNew_game_open(!new_game_open) }}><div> New Game </div></div>
            <Popover
                className="popover-styles new-game-popover"
                open={new_game_open}
                anchorEl={anchorEl}
                onClose={() => setNew_game_open(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div>Rows</div>
                <div>
                    <Slider
                        className="slider-with-marker"
                        track="inverted"
                        valueLabelDisplay="auto"
                        min={1}
                        max={80}
                        onChange={(e, value) => setSlider_rows(value)}
                        value={slider_rows}
                        step={1}
                        marks={[{ value: 10 }, { value: 20 }, { value: 30 }, { value: 40 }, { value: 50 }, { value: 60 }, { value: 70 },]}
                    />
                </div>

                <div>Columns</div>
                <div>
                    <Slider 
                        className="slider-with-marker"
                        track="inverted"
                        valueLabelDisplay="auto"
                        min={1}
                        max={80}
                        onChange={(e, value) => setSlider_columns(value)}
                        value={slider_columns}
                        step={1}
                        marks={[{ value: 10 }, { value: 20 }, { value: 30 }, { value: 40 }, { value: 50 }, { value: 60 }, { value: 70 },]}
                    />
                </div>

                <div>Mines</div>
                <div>
                    <Slider
                        className="slider-with-marker"
                        track="inverted"
                        valueLabelDisplay="auto"
                        min={1}
                        max={(parseInt(slider_rows) * parseInt(slider_columns) - 1)}
                        onChange={(e, value) => setSlider_mines(value)}
                        value={slider_mines}
                        step={1}
                        marks={[]}
                    />
                </div>
                <div>
                    <Button variant="contained" color="primary" onClick={(e) => { setNew_game_open(false); createGame(slider_columns, slider_rows, slider_mines > (parseInt(slider_rows) * parseInt(slider_columns) - 1) ? (parseInt(slider_rows) * parseInt(slider_columns) - 1) : slider_mines) }}> Start Game</Button>
                </div>
            </Popover>
        </Fragment>
    )
}

export default Sudoku